import MainPage from "./pages/MainPage";

function App() {
  return (
    <>
      <MainPage/>
    </>
  );
}

export default App;
